import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {withViewport} from 'contexts/ViewportContext';
import {VIEWPORT_WIDTHS} from 'configs';

class Component extends React.Component {
  static defaultProps = {
    side: 'left',
  };

  render() {
    const {side, showAt, width, children} = this.props;
    const {size} = this.props.viewport;

    if (VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS[showAt]) return null;

    return (
      <aside
        style={{width: width}}
        className={classNames(styles['page__sidebar'], {
          [styles[`page__sidebar--${side}`]]: !!side,
        })}
      >
        <div style={{width: width}} className={styles['page__sidebar__inner']}>
          {children}
        </div>
      </aside>
    );
  }
}

export default withViewport(Component);
