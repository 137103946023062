import Loadable from 'components/Loadable';

const membership = {
  path: '/membership',
  component: Loadable({
    loader: () => import('../views/membership'),
  }),
  // routes: [
  //   {
  //     exact: true,
  //     path: '/membership',
  //     component: Loadable({
  //       loader: () => import('../views/membership/overview'),
  //     }),
  //   },
  //   {
  //     path: '/membership/invoices',
  //     component: Loadable({
  //       loader: () => import('../views/membership/invoices'),
  //     }),
  //   },
  //   {
  //     path: '/membership/consumption-tracker',
  //     component: Loadable({
  //       loader: () => import('../views/membership/consumption-tracker'),
  //     }),
  //   },
  // ],
};

export default membership;
