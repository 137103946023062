import React, {createContext, useContext, useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {ONBOARD_DEFAULT_VALUE} from 'configs/onboarding';

import LocalStorage, {removeItem} from 'utils/localStorage';
import {useAuth} from './AuthContext';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';

const OnboardingContext = createContext({});

function OnboardingProvider(props) {
  const {children} = props;
  const {isAuth} = useAuth();
  const storedData = LocalStorage.userOnboarding;

  const [onboarding, setOnboarding] = useState({
    isPageReady: false,
    props: {},
  });

  useEffect(() => {
    if (isEmpty(storedData) && ENABLE_SUBSCRIPTION) {
      LocalStorage.userOnboarding = ONBOARD_DEFAULT_VALUE;
    }

    if (!ENABLE_SUBSCRIPTION) {
      removeItem('userOnboarding');
    }
  }, [isAuth]);

  return (
    <OnboardingContext.Provider
      value={{
        storedData,
        setOnboarding,
        onboarding,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

const useOnboarding = () => useContext(OnboardingContext);

export {OnboardingContext, OnboardingProvider, useOnboarding};
