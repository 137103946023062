import styles from './PageHeader.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {Navbar} from 'reactstrap';
import {ImageLogo} from 'components/Images';
import {useAuth} from 'contexts/AuthContext';
import PublicNav from './PublicNav';
import PrivateNav from './PrivateNav';

PageHeader.propTypes = {
  logoSize: PropTypes.oneOf(['md', 'lg']),
};

PageHeader.defaultProps = {
  logoSize: 'md',
  dark: false,
  fluid: true,
  fixed: true,
};

function PageHeader(props) {
  const {logoSize, dark, fluid, className, fixed} = props;
  const {isAuth} = useAuth();

  const _className = cx(
    styles['header'],
    {
      [styles['header--fluid']]: fluid,
      [styles['header--light']]: !dark,
      [styles['header--fixed']]: fixed,
    },

    className
  );

  return (
    <header className={_className}>
      <Navbar
        expand="xl"
        dark={dark}
        className={cx('container-fluid', styles['header-nav'])}
      >
        <Link to="/">
          <ImageLogo size={logoSize} />
        </Link>

        {isAuth ? <PrivateNav dark={dark} /> : <PublicNav dark={dark} />}
      </Navbar>
    </header>
  );
}

export default PageHeader;
