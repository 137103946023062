import {combineComponents} from 'utils/combineComponents';

import {AuthProvider} from 'contexts/AuthContext';
import {ConsentProvider} from 'contexts/ConsentContext';
import {ViewportProvider} from 'contexts/ViewportContext';
import {PageProvider} from 'contexts/PageContext';
import {ToastProvider} from 'contexts/ToastContext';
import {SidebarProvider} from 'contexts/SidebarContext';
import {CommonDataProvider} from 'contexts/CommonDataContext';
import {LocaleProvider} from 'contexts/LocaleContext';
import {CaptchaProvider} from 'contexts/CaptchaContext';
import {MailingProvider} from 'contexts/MailingContext';
import {ProgressProvider} from 'contexts/ProgressContext';
import {OnboardingProvider} from 'contexts/OnboardingContext';
import {SubscriptionProvider} from 'contexts/SubscriptionContext';

const providers = [
  ConsentProvider,
  AuthProvider,
  SubscriptionProvider,
  ViewportProvider,
  PageProvider,
  SidebarProvider,
  CommonDataProvider,
  LocaleProvider,
  CaptchaProvider,
  ToastProvider,
  ProgressProvider,
  MailingProvider,
  OnboardingProvider,
];
const AppContextProvider = combineComponents(...providers);

export default AppContextProvider;
