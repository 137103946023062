import Loadable from 'components/Loadable';

const pricing = {
  exact: true,
  path: '/pricing',
  component: Loadable({
    loader: () => import('../views/pricing'),
  }),
  // routes: [
  //   {
  //     exact: true,
  //     path: '/pricing',
  //     component: Loadable({
  //       loader: () => import('../views/_pricing/list'),
  //     }),
  //   },
  //   {
  //     exact: true,
  //     path: '/pricing/:planId',
  //     component: Loadable({
  //       loader: () => import('../views/_pricing/view'),
  //     }),
  //   },
  // ],
};

export default pricing;
