import Loadable from 'components/Loadable';

const certifications = {
  path: '/certifications',
  component: Loadable({
    loader: () => import('../views/certifications'),
  }),
  routes: [
    {
      exact: true,
      path: '/certifications',
      component: Loadable({
        loader: () => import('../views/certifications/list'),
      }),
    },
    // {
    //   path: '/certifications/:certId',
    //   component: Loadable({
    //     loader: () => import('../views/certifications/view'),
    //   }),
    //   routes: [
    //     {
    //       exact: true,
    //       path: '/certifications/:certId',
    //       component: Loadable({
    //         loader: () => import('../views/certifications/view/details'),
    //       }),
    //     },
    //   ],
    // },
  ],
};

export default certifications;
