import React, {useState, useContext} from 'react';
import Cookies from 'utils/cookies';
import {CookieConsent} from 'components/CookieConsent';
import {isUndefined} from 'lodash';

// previous implementation
// we set everything up thru useEffect every time the isAuth changes we run auth event
// current implementation
// we set the setting of showing of banner every action for example e.g login, register or logout
// Notes
// Unauthenticated user - relies on click of consent banner to be tracked
// Authenticated user - automatically tracked

const ConsentContext = React.createContext({});

function ConsentProvider(props) {
  const {children} = props;
  const hasCookieConsent = !isUndefined(Cookies.consent);
  const [consentBanner, setConsentBanner] = useState(!hasCookieConsent);

  function setCookie(value) {
    // this won't re set the cookie if cookie consent is already accepted
    if (!Cookies.consent) Cookies.consent = value;
  }

  function hideConsent(value) {
    setCookie(value);
    setConsentBanner(false);
  }

  function acceptConsent() {
    hideConsent(true);
  }

  function declineConsent() {
    hideConsent(false);
  }

  return (
    <ConsentContext.Provider
      value={{consentBanner, acceptConsent, declineConsent}}
    >
      {children}
      <CookieConsent
        visible={consentBanner}
        onAccept={acceptConsent}
        onDecline={declineConsent}
      />
    </ConsentContext.Provider>
  );
}

const useConsent = () => useContext(ConsentContext);

export {ConsentContext, ConsentProvider, useConsent};
