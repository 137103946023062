import {UUID_VERSION} from 'configs';
import isUUID from 'validator/es/lib/isUUID';

/**
 * Checks if str has prefix
 * @param {string} str
 * @param {string} prefix
 * @returns {boolean}
 */
export const hasPrefix = (str, prefix) => {
  return !!str && str.startsWith(prefix);
};

/**
 * Adds prefix if it doesn't exist
 * @param {string} str
 * @param {string} prefix
 * @returns {string}
 */
export const addPrefix = (str, prefix) => {
  return hasPrefix(str, prefix) ? str : prefix.concat(str);
};

/**
 * Removes prefix if it exists
 * @param {string} str
 * @param {string} prefix
 * @return {string}
 */
export const removePrefix = (str, prefix) => {
  return hasPrefix(str, prefix) ? str.slice(prefix.length) : str;
};

/**
 * Checks if the string is UUID
 * @param {string} str
 * @returns {boolean}
 */
export const uuidValidate = (str) => {
  return isUUID(str, UUID_VERSION);
};
