import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {DEFAULT_IMAGES, IMAGE_WIDTHS, SIZES} from 'configs';
import {keys} from 'lodash/object';
import {fileToImageUrl} from 'utils/images';

class Image extends React.Component {
  static propTypes = {
    src: PropTypes.string, // Image URI
    file: PropTypes.string, // Stringify Image Data
    defaultSrc: PropTypes.string, // Default Image URI
    size: PropTypes.oneOf(keys(IMAGE_WIDTHS)),
    block: PropTypes.bool,
  };

  static defaultProps = {
    alt: '',
    size: SIZES.md,
    block: false,
  };

  getSrc() {
    const {src, file, size, defaultSrc} = this.props;

    let url = undefined;

    if (src) {
      url = src;
    } else if (file) {
      url = fileToImageUrl(file, size);
    }

    return url || defaultSrc || DEFAULT_IMAGES[size];
  }

  render() {
    const {src, file, size, defaultSrc, block, alt, className, ...rest} =
      this.props;

    return (
      <img
        className={classNames('', {
          'd-block': block,
          [className]: !!className,
        })}
        src={this.getSrc()}
        alt={alt}
        {...rest}
      />
    );
  }
}

export default Image;
