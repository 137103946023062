import {encrypt, decrypt} from './encrypt';
import {isEmpty} from 'lodash/lang';

import {COOKIE_PREFIX, DATA_VERSION} from 'configs';

const _getItem = (name) => {
  /*
  return decrypt(localStorage.getItem(`${COOKIE_PREFIX}_${name}`), SECRET_KEY);
  */

  const itemName = `${COOKIE_PREFIX}_${name}`;
  const item = localStorage.getItem(itemName);

  if (!item) return null;

  const {version, data} = decrypt(item) || {};
  if (version !== DATA_VERSION) return null;

  return data;
};

const _setItem = (name, value) => {
  /*
  value = encrypt(value, SECRET_KEY);
  localStorage.setItem(`${COOKIE_PREFIX}_${name}`, value);
  */

  if (name && value) {
    const itemName = `${COOKIE_PREFIX}_${name}`;
    const item = encrypt({version: DATA_VERSION, data: value});
    localStorage.setItem(itemName, item);
  }
};

const _setItemTTL = (name, value, days) => {
  const itemName = `${COOKIE_PREFIX}_${name}`;
  let d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000); // Day
  const item = encrypt({expiry: d, data: JSON.stringify(value)});
  localStorage.setItem(itemName, item);
};

const _getItemTTL = (name) => {
  const itemName = `${COOKIE_PREFIX}_${name}`;
  const item = localStorage.getItem(itemName);
  const itemStorage = decrypt(item);

  if (!itemStorage) return null;

  const {expiry, data} = itemStorage;
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(itemName);
    return null;
  }
  return JSON.parse(data);
};

export const removeItem = (name) => {
  const itemName = `${COOKIE_PREFIX}_${name}`;
  const item = localStorage.getItem(itemName);
  const itemStorage = decrypt(item);

  if (!itemStorage || isEmpty(itemStorage)) return;

  localStorage.removeItem(itemName);
};

const LocalStorage = {
  get countries() {
    const item = _getItem('countries');

    return isEmpty(item) ? {} : item;
  },
  set countries(value) {
    _setItem('countries', value);
  },

  get activations() {
    const item = _getItem('activations');

    return isEmpty(item) ? {} : item;
  },
  set activations(value) {
    _setItem('activations', value);
  },

  get locale() {
    const item = _getItem('locale');

    return isEmpty(item) ? {} : item;
  },
  set locale(value) {
    _setItem('locale', value);
  },

  get captcha() {
    const item = _getItem('captcha');

    return isEmpty(item) ? '' : item;
  },
  set captcha(value) {
    _setItem('captcha', value);
  },
  get industries() {
    const item = _getItem('industries');

    return isEmpty(item) ? [] : item;
  },
  set industries(value) {
    _setItem('industries', value);
  },
  get occupations() {
    const item = _getItemTTL('occupations');

    return isEmpty(item) ? [] : item;
  },
  set occupations(value) {
    _setItemTTL('occupations', value, 1);
  },
  get dontShowProgress() {
    const item = _getItem('dontShowProgress');

    return isEmpty(item) ? 0 : item;
  },
  set dontShowProgress(value) {
    _setItem('dontShowProgress', value);
  },
  get dontShowAgreementsModal() {
    const item = _getItem('dontShowAgreementsModal');

    return isEmpty(item) ? {} : item;
  },
  set dontShowAgreementsModal(value) {
    _setItem('dontShowAgreementsModal', value);
  },

  get userOnboarding() {
    const item = _getItem('userOnboarding');

    return isEmpty(item) ? {} : item;
  },
  set userOnboarding(value) {
    _setItem('userOnboarding', value);
  },

  get dontShowImportConfirm() {
    const item = _getItem('dontShowImportConfirm');

    return isEmpty(item) ? {} : item;
  },
  set dontShowImportConfirm(value) {
    _setItem('dontShowImportConfirm', value);
  },
};

export default LocalStorage;
