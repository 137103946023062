import http, {makeSearchParams} from 'utils/http';

export function getMembershipBillingTotalStats() {
  return http('GET', 'membership-billing/total-stats');
}

export function getMembershipBillingCostBreakdown() {
  return http('GET', 'membership-billing/cost-breakdown');
}

export function getMembershipBillingInvoice(queries) {
  return http('GET', `membership-billing/invoice`, makeSearchParams(queries));
}

export function getMembershipBillingConsumption(queries) {
  return http(
    'GET',
    'membership-billing/consumption',
    makeSearchParams(queries)
  );
}

export function getMembershipBillingBillingProfile() {
  return http('GET', 'membership-billing/billing-profile');
}

export function putMembershipBillingUpdateProfile(formData) {
  return http('PUT', 'membership-billing/update-profile', formData);
}

// new
export function getMembershipBillingManage(data) {
  return http('POST', '/membership-billing/manage', data);
}
