import React from 'react';

import {Nav} from 'reactstrap';

import PrivateNavUser from './PrivateNavUser';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';
import {useSubscription} from 'contexts/SubscriptionContext';

import {BaseButton} from 'components/Buttons';
import {useAuth} from 'contexts/AuthContext';
import {get} from 'lodash';
import {ACCOUNT_ROLES} from 'configs';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {COMPANY_TYPE} from 'configs/types';

function PrivateNav(props) {
  const auth = useAuth();
  const {dark} = props;
  const {isSubscribed} = useSubscription();
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;
  const isCompany = get(auth, 'companyType') === COMPANY_TYPE.company;

  return (
    <>
      <div className="align-items-lg-center d-none d-lg-block">
        <PrivateNavSearch />
      </div>

      <Nav className="align-items-center ml-auto flex-row" navbar>
        {isSuperAdmin && ENABLE_SUBSCRIPTION && isCompany && (
          <div>
            <BaseButton
              to="/pricing?redirect=dashboard"
              className="mr-3 rounded-sm"
            >
              {isSubscribed ? 'Change Plan' : 'Upgrade'}
            </BaseButton>
          </div>
        )}
        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
