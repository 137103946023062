export const ONBOARD_DEFAULT_VALUE = {
  dashboard: true,
  companyList: true,
  certifications: true,
  membership: true,
  importManagement: true,
  importSettings: true,
  users: true,
};

export const ONBOARD_DASHBOARD = [
  {
    target: '.onboard-companies-tab',
    title: 'Companies',
    content: 'Manage and keep your company verification records up-to-date.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-certs-tab',
    title: 'Certifications',
    content: 'Organize and receive updates on your certification records.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-import-tab',
    title: 'Import Company Data',
    content:
      'Match company and get UNLIMITED Certificate Verification in BULK.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-import-settings-tab',
    title: 'Import Settings',
    content:
      'Customize your data import process to fit your needs and  ensure a seamless and accurate import experience.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-membership-tab',
    title: 'Membership & Billing',
    content:
      'View your plan and update payment information easily ensuring a seamless access without interruptions.',
    placement: 'right',
    disableBeacon: true,
  },
];

export const ONBOARD_COMPANY_LIST = [
  {
    target: '.onboard-rerun-lookup',
    title: 'Rerun Company Lookup',
    content:
      'Match your company list against the entities in the IAF CertSearch which includes checking entity match and updating the certificate count.',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-new-certs',
    title: 'New Certificates',
    content: 'Instantly identify new certificates for streamlined verification',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-certs-stats',
    title: 'View Certifications',
    content: 'View the certification data for all matched entities.',
    placement: 'right',
    disableBeacon: true,
  },
];

export const ONBOARD_CERTS_LIST = [
  {
    target: '.onboard-adding-tags',
    title: 'Adding - Tags',
    content:
      'Tagging helps you organize your company list based on criteria like sector, industry, or location. This makes it easier to find and manage companies according to your preferences, streamlining your workflow and improving efficiency.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-add-watchlist',
    title: 'Add to Watchlist',
    content:
      'Monitor specific companies closely, receive notifications for any certification updates or changes. This feature ensures you stay informed about key companies, helping you make timely decisions and stay ahead in your business.',
    placement: 'right',
    disableBeacon: true,
  },
];

export const ONBOARD_MEMBERSHIP = [
  {
    target: '.onboard-manage-subscription',
    title: 'Manage Your Subscription',
    content:
      'Easily manage your subscription to fit your needs. Access the features that are most important to you by managing your subscription at any time.',
    placement: 'left',
    disableBeacon: true,
  },
  {
    target: '.onboard-current-plan',
    title: 'Current Plan',
    content:
      'View and manage your current subscription plan. See details such as plan features and renewal cycle.',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-upcoming-bill',
    title: 'Upcoming Bill',
    content:
      'Stay informed about your next bill with details on the amount due, billing date, and payment method.',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-verification-limits',
    title: 'Verification Limits',
    content:
      'Keep track of your verification limits, which indicate the maximum number of verifications allowed within a specific timeframe. Monitor your usage and consider upgrading your plan if you need to verify more entries.',
    placement: 'top',
    disableBeacon: true,
  },
];

export const ONBOARD_IMPORT_MANAGEMENT = [
  {
    target: '.onboard-file-import',
    title: 'File Import',
    content:
      'Efficiently validate multiple entries by utilizing our file import tool. Save time and streamline your verification process with bulk verification.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-downloadable-template',
    title: 'Downloadable Data Template',
    content: `Use our template to format your company data for 
Download a standardized data template to easily organize and prepare your company data for upload. This template ensures that your data is formatted correctly, saving you time and effort in the data entry process.`,
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-select-file',
    title: 'Select file to import',
    content:
      'Easily upload the company records you want to validate by selecting the file you want to import.',
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.onboard-import-tags',
    title: 'Add Tags',
    content:
      'Easily categorize companies in your records with custom tags. Tags allow for the organization of companies based on priority level, sectors, industry, or other criteria.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-existing-records',
    title: 'Updating Existing Records',
    content:
      'Easily update existing records without creating duplicates. This feature helps ensure an accurate database by synchronizing information for records that are already in the database.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-record-identifier',
    title: 'Record Identifier',
    content:
      'This feature allows you to easily locate specific company records that you have already imported, based on their Company Name, VAT number, tax ID, or other legal identifiers.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-duplicate-records',
    title: 'Handling Duplicated Records',
    content:
      'Our deduplication tool efficiently handles duplicate records by enabling users to select which record to import - either the first or last identified record.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-start-import',
    title: 'Start Import',
    content:
      'Initiate the import to match and verify your company records with the click of the Start Import button.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-recent-activities',
    title: 'Recent Import Activities',
    content:
      'Easily track the progress of your imports, receive notifications for completed imports, and access detailed import logs for reference.',
    placement: 'left',
    disableBeacon: true,
  },
];

export const ONBOARD_IMPORT_SETTINGS = [
  {
    target: '.onboard-import-settings',
    title: 'Settings',
    content:
      'Configure your standard import configuration so whenever you upload files, these settings will be automatically applied by default.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.onboard-import-mapping',
    title: 'Mapping',
    content:
      'Map your data fields for seamless integration and accurate import. Our mapping feature aligns your fields with IAF CertSearch, ensuring correct interpretation and import of your data.',
    placement: 'right',
    disableBeacon: true,
  },
];
