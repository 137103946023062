import {keys, pick} from 'lodash/object';
import urlJoin from 'url-join';

import ERROR_MESSAGES from './ErrorMessages';
import EXTERNAL_URL from './ExternalUrl';

export {ERROR_MESSAGES, EXTERNAL_URL};

//
// ENV
export const IS_STAGE_DEV =
  process.env.REACT_APP_STAGE === 'development' ||
  process.env.REACT_APP_STAGE === 'alpha' ||
  process.env.REACT_APP_STAGE === 'staging' ||
  process.env.REACT_APP_STAGE === 'beta';
export const IS_ENV_DEV = process.env.NODE_ENV === 'development';
export const IS_ENV_PROD = process.env.NODE_ENV === 'production';
export const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
export const DATA_VERSION = process.env.REACT_APP_DATA_VERSION;
export const ENABLE_COMPLETED_MIGRATION =
  process.env.REACT_APP_ENABLE_COMPLETED_MIGRATION === 1 ||
  process.env.REACT_APP_ENABLE_COMPLETED_MIGRATION === '1';
export const ENABLE_ONGOING_MIGRATION =
  process.env.REACT_APP_ENABLE_ONGOING_MIGRATION === 1 ||
  process.env.REACT_APP_ENABLE_ONGOING_MIGRATION === '1';

export const UUID_VERSION = process.env.REACT_APP_UUID_VERSION;
//
// KEY
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const GMAP_KEY = process.env.REACT_APP_GMAP_KEY;
export const RECAPTCA_KEY = process.env.REACT_APP_RECAPTCA_SITEKEY;
export const HCAPTCHA_SITEKEY = process.env.REACT_APP_HCAPTCHA_SITEKEY;
export const QUALITYTRADE_KEY = process.env.REACT_APP_QUALITYTRADE_KEY;
export const COUNTRY_VERSION = process.env.REACT_APP_COUNTRY_VERSION;

//
// URL
export const PUBLIC_URL = process.env.PUBLIC_URL || '/';
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const API_URL = process.env.REACT_APP_API_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_FILE_URL = process.env.REACT_APP_CDN_FILE_URL;

export const CDN_IMAGE_URL = urlJoin(CDN_URL, 'r'); // CDN global resources.
export const CDN_PAGE_RESOURCE_URL = urlJoin(CDN_IMAGE_URL, 'p'); // CDN local resources for each page.

//
// Instructions
export const INSTRUCTIONS_URL = 'https://developer.iafcertsearch.org';
export const INSTRUCTION_FILE_UPLOAD = urlJoin(INSTRUCTIONS_URL, 'file-upload');
export const INSTRUCTION_FTP_ACCESS = urlJoin(INSTRUCTIONS_URL, 'ftp-access');
export const INSTRUCTION_API_ACCESS = urlJoin(INSTRUCTIONS_URL, 'api-access');
export const INSTRUCTION_DATA_MAPPING = urlJoin(
  INSTRUCTIONS_URL,
  'file-upload'
);

export const EMAIL_SUPPORT = 'support@iafcertsearch.org';

const HOST = window.location.protocol + window.location.host;

export const BADGE_REDIRECT_URL = urlJoin(HOST, '/r/b');
export const COMPANY_ACTIVATE_URL = urlJoin(HOST, '/act');

//
// STATUS_TYPES
export const STATUS_TYPES = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
  hidden: 'hidden',
  cancelled: 'cancelled',
  withdrawn: 'withdrawn',
  cbwithdrawn: 'cbwithdrawn',
  suspended: 'suspended',
  deleted: 'deleted',
  open: 'open',
  closed: 'closed',
  error: 'error',
  success: 'success',
  expired: 'expired',
  // new status for data management,
  uploading: 'uploading',
  downloading: 'downloading',
  downloaded: 'downloaded',
  skipped: 'skipped',
  // new status for signed agreements
  declined: 'declined',
  // new status for watchlist
  valid: 'valid',
  invalid: 'invalid',
};

export const SORT_BY_DATE_TYPES = {
  date_created_asc: {
    order_by: 'created_at',
    order: 'ASC',
  },
  date_created_desc: {
    order_by: 'created_at',
    order: 'DESC',
  },
  date_updated_asc: {
    order_by: 'updated_at',
    order: 'ASC',
  },
  date_updated_desc: {
    order_by: 'updated_at',
    order: 'DESC',
  },
  date_expired_asc: {
    order_by: 'expiry_date',
    order: 'ASC',
  },
  date_expired_desc: {
    order_by: 'expiry_date',
    order: 'DESC',
  },
};

export const SORT_BY_DATE_LABELS = {
  date_created_asc: 'Date created (ASC)',
  date_created_desc: 'Date created (DESC)',
  date_updated_asc: 'Date updated (ASC)',
  date_updated_desc: 'Date updated (DESC)',
  date_expired_asc: 'Date expired (ASC)',
  date_expired_desc: 'Date expired (DESC)',
};

export const STATUS_LABELS = {
  [STATUS_TYPES.active]: 'Active',
  [STATUS_TYPES.inactive]: 'Inactive',
  [STATUS_TYPES.pending]: 'Pending',
  [STATUS_TYPES.hidden]: 'Draft', // 'hidden' status is displayed as 'draft'
  [STATUS_TYPES.withdrawn]: 'Withdrawn',
  [STATUS_TYPES.cbwithdrawn]: 'CB Withdrawn',
  [STATUS_TYPES.cancelled]: 'Cancelled',
  [STATUS_TYPES.suspended]: 'Suspended',
  [STATUS_TYPES.deleted]: 'Deleted',
  [STATUS_TYPES.open]: 'Open',
  [STATUS_TYPES.closed]: 'Resolved',
  [STATUS_TYPES.error]: 'Error',
  [STATUS_TYPES.success]: 'Success',
  [STATUS_TYPES.expired]: 'Expired',
  // new statuses for data management
  [STATUS_TYPES.uploading]: 'Uploading',
  [STATUS_TYPES.downloading]: 'Downloading',
  [STATUS_TYPES.downloaded]: 'Downloaded',
  [STATUS_TYPES.skipped]: 'Skipped',
  // new statuses for signed agreements
  [STATUS_TYPES.declined]: 'Declined',
};

export const WATCH_LIST_STATUS_LABELS = {
  [STATUS_TYPES.active]: 'Valid',
  [STATUS_TYPES.inactive]: 'Invalid',
};

export const CERTIFICATION_STATUS_LABELS = {
  [STATUS_TYPES.active]: 'Valid',
};

export const CERTIFICATION_STATUS_COLORS = {
  [STATUS_TYPES.active]: 'success',
};

export const CB_ACCREDITATION_COLORS = {
  [STATUS_TYPES.active]: 'success',
  [STATUS_TYPES.withdrawn]: 'danger',
  [STATUS_TYPES.suspended]: 'warning',
};

export const ACCOUNT_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.pending,
    STATUS_TYPES.suspended,
  ]),
  ACCOUNT_STATUS_OPTIONS = pick(STATUS_LABELS, keys(ACCOUNT_STATUSES)),
  COMPANY_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.pending,
    STATUS_TYPES.suspended,
    STATUS_TYPES.withdrawn,
  ]),
  COMPANY_STATUS_OPTIONS = pick(STATUS_LABELS, keys(COMPANY_STATUSES)),
  ENQUIRY_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.open,
    STATUS_TYPES.closed,
  ]),
  ENQUIRY_STATUS_OPTIONS = pick(STATUS_LABELS, keys(ENQUIRY_STATUSES)),
  STANDARD_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.deleted,
  ]),
  STANDARD_STATUS_OPTIONS = pick(STATUS_LABELS, keys(STANDARD_STATUSES)),
  CB_STANDARD_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.withdrawn,
    STATUS_TYPES.suspended,
    STATUS_TYPES.deleted,
  ]),
  CB_ACCREDITATION_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.withdrawn,
    STATUS_TYPES.suspended,
  ]),
  CB_STANDARD_STATUS_OPTIONS = pick(STATUS_LABELS, keys(CB_STANDARD_STATUSES)),
  CERTIFICATION_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.suspended,
    STATUS_TYPES.cancelled,
    STATUS_TYPES.hidden,
    STATUS_TYPES.withdrawn,
    STATUS_TYPES.expired,
    STATUS_TYPES.valid,
    STATUS_TYPES.invalid,
  ]),
  CERTIFICATION_STATUS_OPTIONS = pick(
    STATUS_LABELS,
    keys(CERTIFICATION_STATUSES)
  ),
  BADGE_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.suspended,
  ]),
  BADGE_STATUS_OPTIONS = pick(STATUS_LABELS, keys(BADGE_STATUSES)),
  FTP_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
  ]),
  FTP_STATUS_OPTIONS = pick(STATUS_LABELS, keys(FTP_STATUSES)),
  MAPPING_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
  ]),
  MAPPING_STATUS_OPTIONS = pick(STATUS_LABELS, keys(MAPPING_STATUSES)),
  STATS_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
  ]),
  STATS_STATUS_OPTIONS = pick(STATUS_LABELS, keys(STATS_STATUSES)),
  WATCH_LIST_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
  ]),
  WATCH_LIST_STATUS_OPTIONS = pick(
    WATCH_LIST_STATUS_LABELS,
    keys(WATCH_LIST_STATUSES)
  ),
  SORT_BY_DATE_FILTERS = pick(SORT_BY_DATE_TYPES, [
    'date_created_asc',
    'date_created_desc',
    'date_updated_asc',
    'date_updated_desc',
    'date_expired_asc',
    'date_expired_desc',
  ]),
  SORT_BY_DATE_FILTER_OPTIONS = pick(
    SORT_BY_DATE_LABELS,
    keys(SORT_BY_DATE_FILTERS)
  );

export const COMPANY_TYPES = {
  iaf: 'iaf',
  ab: 'ab',
  cb: 'cb',
  ce: 'ce',
  company: 'company',
  mncb: 'mncb',
};

export const COMPANY_TYPE_LABELS = {
  [COMPANY_TYPES.ab]: 'Accreditation Body',
  [COMPANY_TYPES.cb]: 'Certification Body',
  [COMPANY_TYPES.ce]: 'Certified Company',
  [COMPANY_TYPES.company]: 'company',
  [COMPANY_TYPES.mncb]: 'mncb',
  [COMPANY_TYPES.iaf]: 'IAF',
};

export const LOCATION_TYPES = {
    country: 'country',
    administrative_area_level_1: 'state',
    administrative_area_level_2: 'suburb',
    sublocality_level_1: 'suburb',
    sublocality_level_2: 'city',
    locality: 'city',
    route: 'street',
    //street_number: 'street_number',
    postal_code: 'postcode',
  },
  FTP_PROTOCOLS = {
    ftp: 'ftp',
    sftp: 'sftp',
  },
  FTP_PROTOCOL_OPTIONS = {
    [FTP_PROTOCOLS.ftp]: 'FTP - File Transfer Protocol',
    [FTP_PROTOCOLS.sftp]: 'SFTP - SSH File Transfer Protocol',
  },
  FTP_FILE_TYPE = {
    ce: 'ce',
    cb: 'cb',
  },
  FTP_FILE_TYPE_OPTIONS = {
    [FTP_FILE_TYPE.ce]: 'Certification',
    [FTP_FILE_TYPE.cb]: 'Certification Body',
  },
  FTP_FILE_TYPE_CERT_OPTIONS = {
    [FTP_FILE_TYPE.ce]: 'Certification',
  },
  FTP_FILE_EXTENSION = {
    xml: 'xml',
    xls: 'xls',
    xlsx: 'xlsx',
  },
  FTP_FILE_EXTENSION_OPTIONS = {
    [FTP_FILE_EXTENSION.xml]: 'XML',
    [FTP_FILE_EXTENSION.xls]: 'XLS',
    [FTP_FILE_EXTENSION.xlsx]: 'XLSX',
  },
  FTP_FILE_EXTENSION_MNCB_OPTIONS = {
    [FTP_FILE_EXTENSION.xml]: 'XML',
  };

//
// SIZES
export const SIZES = {
  origin: 'origin',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const VIEWPORT_WIDTHS = {
  [SIZES.xs]: 0,
  [SIZES.sm]: 576,
  [SIZES.md]: 768,
  [SIZES.lg]: 992,
  [SIZES.xl]: 1200,
};

export const IMAGE_WIDTHS = {
  [SIZES.sm]: 160,
  [SIZES.md]: 320,
  [SIZES.lg]: 640,
  [SIZES.origin]: SIZES.origin,
};

export const BADGE_SIZES = {
  [SIZES.sm]: {width: 120, height: 120},
  [SIZES.md]: {width: 150, height: 150},
  [SIZES.lg]: {width: 180, height: 180},
};

export const MIGRATION_IMAGES = {
  ongoing: urlJoin(CDN_IMAGE_URL, '/p/guides/img-migrating.svg'),
  completed: urlJoin(CDN_IMAGE_URL, '/p/guides/img-migrated.svg'),
  summary: urlJoin(CDN_IMAGE_URL, '/p/guides/img-dm-update-summary.svg'),
  batch_upload: urlJoin(
    CDN_IMAGE_URL,
    '/p/guides/img-dm-update-batch-upload.svg'
  ),
  auto_import: urlJoin(
    CDN_IMAGE_URL,
    '/p/guides/img-dm-update-auto-import.svg'
  ),
  error_handling: urlJoin(
    CDN_IMAGE_URL,
    '/p/guides/img-dm-update-error-handling.svg'
  ),
  data_processing: urlJoin(
    CDN_IMAGE_URL,
    '/p/guides/img-dm-update-data-processing.svg'
  ),
};

//
// IMAGES
export const DEFAULT_IMAGES = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'img_default_80.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'img_default_160.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'img_default_320.png'),
};

export const QT_LOGOS = {
  default: urlJoin(CDN_IMAGE_URL, 'logo_qt_default.png'),
  light: urlJoin(CDN_IMAGE_URL, 'logo_qt_light.png'),
  defaultTag: urlJoin(CDN_IMAGE_URL, 'logo_qt_tag_default_v1.0.png'),
  lightTag: urlJoin(CDN_IMAGE_URL, 'logo_qt_tag_light_v1.0.png'),
};

export const IAF_LOGOS = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'logo_iaf.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'logo_iaf.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'logo_iaf.png'),
};

export const IAF_LOGOS_NEW = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'iaf-logo-w256.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'iaf-logo-w256.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'iaf-logo-w256.png'),
};

export const IAF_CS_LOGOS = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'logo_60x40@2x.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'logo_205x125@2x.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'logo_205x125@2x.png'),
};

export const DEFAULT_AB_LOGO = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ab_80.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ab_160.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ab_320.png'),
};

export const DEFAULT_CB_LOGO = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_cb_80.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_cb_160.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_cb_320.png'),
};

export const DEFAULT_CE_LOGO = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ce_80.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ce_160.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_ce_320.png'),
};

export const DEFAULT_COMPANY_LOGO = {
  [SIZES.sm]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_nu_80.png'),
  [SIZES.md]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_nu_160.png'),
  [SIZES.lg]: urlJoin(CDN_IMAGE_URL, 'img_default_logo_nu_320.png'),
};

export const IMAGE_MARK = urlJoin(CDN_IMAGE_URL, 'iaf_cs_badge.png');

export const IMAGE_AB_BADGES = {
  [SIZES.sm]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@AB.png'),
  [SIZES.md]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@AB.png'),
  [SIZES.lg]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@AB.png'),
};

export const IMAGE_CB_BADGES = {
  [SIZES.sm]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CB.png'),
  [SIZES.md]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CB.png'),
  [SIZES.lg]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CB.png'),
};

export const IMAGE_CE_BADGES = {
  [SIZES.sm]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CE.png'),
  [SIZES.md]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CE.png'),
  [SIZES.lg]: urlJoin(PUBLIC_URL, 'images/iaf_cs_badge@CE.png'),
};

//
// ACCOUNT
export const ACCOUNT_ROLES = {
    super_admin: 'super_admin',
    admin: 'admin',
    staff: 'staff',
  },
  ACCOUNT_ROLE_OPTIONS = {
    [ACCOUNT_ROLES.super_admin]: 'Super Admin',
    [ACCOUNT_ROLES.admin]: 'Admin',
    [ACCOUNT_ROLES.staff]: 'Staff',
  },
  ACCOUNT_ROLE_LEVEL = {
    [ACCOUNT_ROLES.super_admin]: 100,
    [ACCOUNT_ROLES.admin]: 50,
    [ACCOUNT_ROLES.staff]: 10,
  };

//
// COMPANY

export const IS_DEFAULT = {
  1: true,
  0: false,
  false: false,
};

export const COMPANY_TYPE_LEVEL = {
  [COMPANY_TYPES.ab]: 100,
  [COMPANY_TYPES.mncb]: 55,
  [COMPANY_TYPES.cb]: 50,
  [COMPANY_TYPES.ce]: 10,
  [COMPANY_TYPES.company]: 1,
};

//
// ENQUIRY

export const ENQUIRY_CATEGORY = {
  support: 'Support',
  account: 'Account',
  business: 'Business',
  pricing: 'Pricing',
  quotation: 'Quotation',
};

//
// MLA
export const MLA_SCOPE_TYPE = {
  Main: 'Main',
  Sub: 'Sub',
};

export const MLA_LEVEL = {
  'Level 4': 'Level 4',
  'Level 5': 'Level 5',
};

export const MLA_DOC_TYPE = {
  'Management System': 'Management System',
  'Product Certification': 'Product Certification',
  'Certifications of Persons': 'Certifications of Persons',
  'Validation and Verification': 'Validation and Verification',
};

export const MLA_STANDARDS = [
  'ISO 22000',
  'ISO 9001',
  'ISO 14001',
  'ISO/IEC 27001',
  'ISO 27001',
  'ISO 13485',
  'ISO 50001',
  'ISO 45001',
];

//
// Branch
export const BRANCH_OFFICE_TYPE = {
  'Corporate Head Office': 'Corporate Head Office',
  'Regional Head Office': 'Regional Head Office',
  'Country Head Office': 'Country Head Office',
  'Branch Office': 'Branch Office',
};

//
// MAPPING
export const MAPPING_TYPES = {
  country: 'Country',
  document_type: 'Document',
  field_name: 'Field Name',
  standard: 'Standard',
  certification_status: 'Certification Status',
};

// Certification Type
export const CERT_TYPE = {
  'Management System': 'Management System',
  'Product Certification': 'Product Certification',
};

//
// SECURITY_TYPE
export const SECURITY_TYPE = {
  sign_in: 'Log in',
  sign_out: 'Log out',
  sign_up: 'Sign up',
  activation: 'Activate account',
  change_password: 'Change password',
  update_profile: 'Update profile',
  add_office_directory: 'Add office directory',
  update_office_directory: 'Update office directory',
  update_user_settings: 'Update user settings',
  delete_account: 'Delete account',
  update_security_settings: 'Update security setting',
  update_notification_settings: 'Update notification setting',
  delete_certification: 'Delete certification',
  delete_all_certifications: 'Delete all certifications',
  delete_all_ce_certifications: 'Delete all certifications under',
};

//
// FILE_UPLOAD
export const FILE_UPLOAD_TYPE = {
  xml: 'XML File',
  xlsx: 'Excel File',
};

//
// CONSENTS

export const CONSENTS = {
  terms: 'terms', // Terms and Conditions
  privacy: 'privacy', // Privacy policies
  data: 'data', // Data Management Agreement
  qt: 'qt', // QualityTrade Policies
};

export const CONSENT_VERSIONS = {
  [CONSENTS.terms]: process.env.REACT_APP_CONSENT_VERSION,
  [CONSENTS.privacy]: process.env.REACT_APP_CONSENT_VERSION,
  [CONSENTS.data]: process.env.REACT_APP_CONSENT_VERSION,
  [CONSENTS.qt]: process.env.REACT_APP_CONSENT_VERSION,
};

//
// REGEXP
export const REGEXP = {
  name: `^[a-zA-Z_ ]*$`,
  // it must be in the following order: characters@characters.domain (characters followed by an @ sign, followed by more characters, and then a "."
  // After the "." sign, you can only write 2 to 3 letters from a to z:
  email: `[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-z]{2,8}$`,
  // Password: Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters
  password: `(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}`,
  // it must start with http:// or https:// followed by at least one character:
  //website: `https?://.+`,
  website: `https?://(www\\.)?[\\-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9\\(\\)]{1,10}\\b([\\-a-zA-Z0-9\\(\\)@:%_\\+.~#?&\\/\\/=]*)`,
  // +### #### #### ####
  phone: `[\\+]\\d{1,3}[\\s?\\-?]?\\d{1,4}[\\s?\\-?]?\\d{3,4}[\\s?\\-?]?\\d{3,4}`,
  websiteNoProtocol: `(www\\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)|(www\\.)?(?!ww)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`,
};

//
// REGEXP_FEEDBACK
export const REGEXP_FEEDBACK = {
  required: 'This field is required.',
  email: 'This field is required. Please provide valid email address.',
  invalid_email: 'Please enter a valid email address. (Ex: example@yahoo.com)',
  password:
    'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.',
  website: 'Website must start with http:// or https://',
  phone:
    'Format must contain country code. Format must be plus(+), number, dash(-) or space. +xx xxx xxx xxxx',
  date: 'This field is required. Format must be YYYY-MM-DD',
  invalid_confirm_password: 'Please make sure your passwords match.',
  websiteNoProtocol: 'Please provide valid URL.',
};

// ------------
// ROUTE_ACCESS_RULES
// ------------
//
// Only Set Rules at the Top Level

export const ROUTE_ACCESS_RULES = {
  // Open to everyone
  public: 'public',

  // Logged in user only
  private: 'private',

  // Non-logged in user only
  guest: 'guest',
};

export const ROUTE_ENV_RULES = {
  // Development only
  development: 'development',

  // Production only
  production: 'production',
};

export const ROUTER_SCROLL_TYPES = {
  stay: 'stay',
  scroll: 'scroll',
};

//
// ERRORS
export const ERROR_STATUS = {
  400: '400', // 400 Bad Request
  401: '401', //  401 Unauthorized
  403: '403', //  403 Forbidden, The client does not have access rights
  404: '404', //  404 Not found
  500: '500', //  500 Internal Server Error
  google_translate: 'google_translate',
};

export const ERROR_STATUS_SUB_HEADER = {
  401: 'Session has been expired.',
  403: 'Forbidden request.',
  404: 'Oops! Page not found.',
  500: 'Server error',
  google_translate:
    'This page is taking too long to translate. Please refresh the page.',
};

export const ERROR_STATUS_HEADER = {
  400: '400',
  401: '401',
  403: '403',
  404: '404',
  500: '500',
  google_translate: 'Try Again',
};

export const DAILY_SEARCH_FREQUENCY = {
  '0-19': '0 - 19 searches',
  '20-49': '20 - 49 searches',
  '50-99': '50 - 99 searches',
  '100-499': '100 - 499 searches',
  '500-999': '500 - 999 searches',
  '1000+': '1000+ searches',
};

export const ONLY_ACCEPTED_IMAGE = {
  image: 'image/*',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  png: 'image/png',
  all: 'image/jpg, image/jpeg, image/png, image/gif',
};

export const IMAGES_FEEDBACK = {
  image: 'Only accepts Image',
  jpg: 'Only accepts JPG file type',
  jpeg: 'Only accepts JPEG file type',
  gif: 'Only accepts GIF file type',
  png: 'Only accepts PNG file type',
  // all: 'Only accepts JPG, JPEG, PNG, and GIF file type',
  all: 'This file type is unsupported. This tool supports JPG, JPEG, PNG, and GIF files.',
};

export function validateImage(fileType) {
  return Object.values(ONLY_ACCEPTED_IMAGE).indexOf(fileType) > -1;
}

export const DATALAYER_ACCOUNT_TYPE = {
  [COMPANY_TYPES.company]: 'verification user',
  [COMPANY_TYPES.ce]: 'certified entity',
  [COMPANY_TYPES.mncb]: 'certification body', // merge mncb to cb as per request
  [COMPANY_TYPES.cb]: 'certification body',
  [COMPANY_TYPES.ab]: 'accreditation body',
};

export const COMPANY_SIZE = {
  '1-10': '1 - 10 employee/s',
  '11-50': '11 - 50 employees',
  '51-200': '51 - 200 employees',
  '201-500': '201 - 500 employees',
  '501-1000': '501 - 1000 employees',
  '1001-5000': '1001 - 5000 employees',
  '5001-10000': '5001 - 10000 employees',
  '10000+': '10000+ employees',
};

export const MDB_PREFIX = {
  ce: 'mdbce_',
  cert: 'mdbcert_',
};

export const DOCUMENTATION_PREFIX = 'https://support.iafcertsearch.org';
export const DOCUMENTATION_LANDING_URL = `${urlJoin(
  DOCUMENTATION_PREFIX,
  '/iaf-certsearch-support'
)}`;

export const DASHBOARD_UPDATES_IMAGES = {
  introduction: urlJoin(CDN_IMAGE_URL, 'vu/carousel/intro-to-bulk-verify.jpg'),
  certification: urlJoin(
    CDN_IMAGE_URL,
    'vu/carousel/certification-listing.jpg'
  ),
  bulkImport: urlJoin(CDN_IMAGE_URL, 'vu/carousel/import-company-data.jpg'),
  watchlist: urlJoin(CDN_IMAGE_URL, 'vu/carousel/watchlist.jpg'),
  tags: urlJoin(CDN_IMAGE_URL, 'vu/carousel/adding-tags.jpg'),
};

export const VERIFIED_IMAGE = urlJoin(CDN_IMAGE_URL, '/vu/verified.svg');
export const WATCHLIST_IMAGE = urlJoin(CDN_IMAGE_URL, '/vu/watchlist.svg');
export const TABLE_EMPTY_IMAGE = urlJoin(CDN_IMAGE_URL, '/vu/table-empty.svg');
