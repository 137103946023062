export const SUBSCRIPTION_STATUS = {
  active: 'active',
  suspended: 'suspended',
  pending: 'pending',
  canceled: 'canceled',
};

export const SUBSCRIPTION_STATUS_LABEL = {
  [SUBSCRIPTION_STATUS.active]: 'Active',
  [SUBSCRIPTION_STATUS.pending]: 'Pending',
  [SUBSCRIPTION_STATUS.suspended]: 'Suspended',
};

export const SUBSCRIPTION_CURRENCY = {
  usd: '$',
};

export const SUBSCRIPTION_BILLING_PERIOD = {
  annual: 'annual',
  monthly: 'monthly',
};

export const SUBSCRIPTION_BILLING_PERIOD_LABEL = {
  [SUBSCRIPTION_BILLING_PERIOD.annual]: 'year',
  [SUBSCRIPTION_BILLING_PERIOD.monthly]: 'month',
};

export const SUBSCRIPTION_PLANS = {
  free: 'free',
  one_off: 'one_off',
};

export const SUBSCRIPTION_PLANS_LABEL = {
  free: 'Free',
  one_off: 'One-off Verification',
};

// New
export const SUBSCRIPTION_PLANS_TYPE = {
  free: 'free',
  pro: 'pro',
  enterprise: 'enterprise',
};
