import React, {useState, useContext, useEffect, createContext} from 'react';
import axios from 'axios';
import {concat} from 'lodash/array';
import {withRouter} from 'react-router';
import {
  getAccountNotifications,
  getAccountNotificationCount,
  updateAccountNotification,
} from 'api/accounts';
import {AuthContext} from 'contexts/AuthContext';
import {IAF_DC_URL} from 'configs/Environment';
import urljoin from 'url-join';

export const NotificationContext = createContext({});
export const NotificationConsumer = NotificationContext.Consumer;

function Provider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [nextKey, setNextKey] = useState('');
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const {location, history, children} = props;
  const {pathname} = location;
  const {isAuth, isDCAccount} = useContext(AuthContext);

  useEffect(() => {
    const isNotPageException = ['/logout'].every((p) => pathname !== p);
    if (isNotPageException) {
      getCount();
    }
  }, [pathname]);

  async function getCount() {
    if (!isAuth) return false;

    // isFetching is not required in this.
    const {data} = await getAccountNotificationCount();
    const {count} = data;

    setCount(count || 0);
  }

  async function getNotifications() {
    if (!isAuth) return false;
    if (isFetchingMore) return false;

    setIsFetchingMore(true);

    const {data: items, meta} = await getAccountNotifications({
      key: nextKey,
      status: 'unread',
    });

    const {next_key} = meta;

    setIsFetchingMore(false);
    setData(concat(data, items));
    setNextKey(next_key || '');
  }

  async function getNotificationsAll() {
    if (!isAuth) return false;
    if (isFetching) return false;

    setIsFetching(true);

    const [counts, notifications] = await axios.all([
      getAccountNotificationCount(),
      getAccountNotifications({
        status: 'unread',
      }),
    ]);

    setIsFetching(false);
    setCount(counts.data.count || 0);
    setData(notifications.data || []);
    setNextKey(notifications.meta.next_key || '');
  }

  async function handleToggle() {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
      getNotificationsAll();
    }
  }

  async function handleClick(status, notificationId, link) {
    if (!isAuth) return false;

    setIsOpen(false);
    if (status === 'unread') {
      await updateAccountNotification({
        notification_id: notificationId,
        status: 'read',
      });
    }

    if (isDCAccount) {
      window.location.replace(urljoin(IAF_DC_URL, link));
    } else {
      history.push(link);
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        isOpen: isOpen,
        isFetching: isFetching,
        isFetchingMore: isFetchingMore,
        data: data,
        nextKey: nextKey,
        count: count,
        handleFetchNotifications: () => getNotifications(),
        handleToggle: () => handleToggle(),
        handleClick: handleClick,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export const NotificationProvider = withRouter(Provider);
