import Loadable from 'components/Loadable';
import {ENABLE_ACTIVATION, ENABLE_INVITATION} from 'configs/enable-features';

const elinks = {
  path: '/e',
  component: Loadable({
    loader: () => import('../views/elinks'),
  }),
  routes: [
    {
      exact: true,
      path: '/e/ce/:token',
      component: Loadable({
        loader: () => import('../views/elinks/confirm-email'),
      }),
    },
    {
      exact: true,
      path: '/e/inv/:token',
      component:
        ENABLE_INVITATION &&
        Loadable({
          loader: () => import('../views/register/invitation'),
        }),
    },
    // {
    //   exact: true,
    //   path: '/e/act/:token',
    //   component: Loadable({
    //     loader: () => import('../views/register/activateByToken/_index'),
    //   }),
    // },
    {
      exact: true,
      path: '/e/act/:companyType/:token/:verified?',
      component:
        ENABLE_ACTIVATION &&
        Loadable({
          loader: () => import('../views/register/activateByToken'),
        }),
    },
  ],
};

export default elinks;
