import Cookies from './cookies';
const stage = process.env.REACT_APP_STAGE;

let oldPayload = {};

function dataLayerPush(event, payload = {}) {
  // we wont allow any data pushes if its on alpha and development mode
  if (
    stage === 'staging' ||
    stage === 'development' ||
    stage === 'production'
  ) {
    // if the user is authenticated and has consent
    // only allows data layer pushes if there is consent
    if (Cookies.consent) {
      const {account, token} = Cookies.session || {};
      const {company} = account || {};
      const {company_type} = company || {};
      Object.keys(oldPayload).forEach((index) => {
        oldPayload[index] = undefined;
      });

      const _payload = {
        ...oldPayload,
        ...payload,
        event,
      };

      // we are going to push this depends on the event and not flush it
      // since this are user property they are important and this should always have value
      window.dataLayer.push({
        ..._payload,
        auth: !!Cookies.session, // dimension of true and false
        authCompanyType: company_type || 'visitor', // dimension of "ab", "cb", "ce", "mncb", "company" and "visitor"
        authSessionId: token || 'empty', // high cardinality so we wont make this as dimension
      });

      oldPayload = _payload;
    }
  }
}

class DataLayerPush {
  eventAuth() {
    dataLayerPush('auth');
  }

  eventSearchInput(payload) {
    const {
      searchInputEntityType,
      searchInputLocation,
      searchInputKeyword,
      searchInputResult,
    } = payload;

    dataLayerPush('search input', {
      searchInputEntityType, // ce, cert
      searchInputLocation, // homepage header
      searchInputKeyword, // asdf
      searchInputResult, // true false
    });
  }

  eventSearchClick(payload) {
    const {
      searchClickEntityType,
      searchClickLocation,
      searchClickKeyword,
      searchClickEntityName,
      searchClickEntityId,
    } = payload;

    dataLayerPush('search click', {
      searchClickEntityType, // ce cert
      searchClickLocation, // hompage header
      searchClickKeyword, // asdf
      searchClickEntityName, // ce name cert name
      searchClickEntityId, // ce id cert name
    });
  }

  eventView(payload) {
    const {
      viewLocation,
      viewEntityName,
      viewEntityId,
      viewEntityCountry,
      viewEntityCity,
    } = payload;

    dataLayerPush('view', {
      viewLocation, // current url
      viewEntityName, // ce name cb name ab name
      viewEntityId, // ce id cb id ab id
      viewEntityCountry,
      viewEntityCity,
    });
  }
}

const dataLayerPushInstance = new DataLayerPush();

export default dataLayerPushInstance;
