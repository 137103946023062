import React from 'react';
import {Redirect} from 'react-router-dom';
import {
  DOCUMENTATION_LANDING_URL,
  ROUTE_ACCESS_RULES,
  ROUTE_ENV_RULES,
} from 'configs';
import Error from 'views/errors';

import about from './about';
import accreditationBody from './accreditation-body';
import activate from './activate';
import certification from './certification';
import certificationBody from './certification-body';
import certifiedEntity from './certified-entity';
import lettersOfSupport from './letters-of-support';
// import certifiedMarketplace from './certified-marketplace';
import coming from './coming';
import companyProfile from './company-profile';
import consents from './consents';
import contact from './contact';
import dashboard from './dashboard';
import downloads from './downloads';
import elinks from './elinks';
import enquiries from './enquiries';
import forgot from './forgot';
import help from './help';
import landing from './landing';
import login from './login';
import logout from './logout';
import marks from './marks';
import policies from './policies';
import redirect from './redirect';
import register from './register';
import reset from './reset';
import search from './search';
import settings from './settings';
import setup from './setup';
import users from './users';
import watchList from './watch-list';
import searchHistory from './search-history';
import publicEnquiry from './enquiry';
import promotions from './promotions';
import companyList from './company-list';

import importManagement from './import-management';
import importSettings from './import-settings';
import companySettings from './company-settings';
import membership from './membership';
import pricing from './pricing';

import {ENABLE_ACTIVATION, ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import certifications from './certifications';

/*
Example:
{
env: ROUTE_ENV_RULES.development,
access: ROUTE_ACCESS_RULES.public,
...certification,
},
*/

export const Replace = ({to}) => {
  React.useEffect(() => {
    window.location.replace(to);
  }, []);
  return null;
};

const routes = [
  {
    access: ROUTE_ACCESS_RULES.public,
    ...about,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...accreditationBody,
  },
  ENABLE_ACTIVATION && {
    access: ROUTE_ACCESS_RULES.public,
    ...activate,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certification,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certificationBody,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certifiedEntity,
  },
  /*{
    access: ROUTE_ACCESS_RULES.private,
    ...certifiedMarketplace,
  },*/
  {
    access: ROUTE_ACCESS_RULES.public,
    ...coming,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...companyProfile,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...companyList,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...consents,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...contact,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...dashboard,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...downloads,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...elinks,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...enquiries,
  },
  {
    access: ROUTE_ACCESS_RULES.guest,
    ...forgot,
  },
  {
    env: ROUTE_ENV_RULES.development,
    access: ROUTE_ACCESS_RULES.public,
    ...help,
  },
  {
    access: ROUTE_ACCESS_RULES.guest,
    ...landing,
  },

  {
    access: ROUTE_ACCESS_RULES.public,
    ...login,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...logout,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...marks,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...policies,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...redirect,
  },
  {
    access: ROUTE_ACCESS_RULES.guest,
    ...register,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...reset,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...search,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...settings,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...setup,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...users,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...watchList,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...searchHistory,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...publicEnquiry,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...lettersOfSupport,
  },
  {
    access: ROUTE_ACCESS_RULES.guest,
    ...promotions,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...importManagement,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...importSettings,
  },
  {
    access: ROUTE_ACCESS_RULES.private,
    ...companySettings,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...membership,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...pricing,
  },
  ENABLE_SUBSCRIPTION && {
    access: ROUTE_ACCESS_RULES.private,
    ...certifications,
  },
  {
    path: '/user-guide',
    component: () => <Replace to={DOCUMENTATION_LANDING_URL} />,
  },
  {
    exact: true,
    path: '/error/:code',
    component: Error,
  },
  {
    exact: true,
    path: '/not-found',
    component: Error,
  },
  {
    component: () => {
      return <Redirect to="/not-found" />;
    },
  },
].filter((v) => v);

export default routes;
