import Loadable from 'components/Loadable';

const policies = {
  path: '/policies',
  exact: false,
  component: Loadable({
    loader: () => import('../views/policies'),
  }),
  routes: [
    {
      exact: true,
      path: '/policies/terms',
      component: Loadable({
        loader: () => import('../views/policies/terms/index.js'),
      }),
    },
    {
      exact: true,
      path: '/policies/privacy',
      component: Loadable({
        loader: () => import('../views/policies/privacy/index.js'),
      }),
    },
  ],
};

export default policies;
