import styles from './styles.module.scss';
import React from 'react';
import {withRouter} from 'react-router';
import {withAuth} from 'contexts/AuthContext';
import DataLayerPush from 'utils/gtm';

import {
  InputGroup,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
} from 'reactstrap';
import {SearchSelectVerification} from 'components/Forms/Searches';
import {CaptchaModal} from 'components/Captcha';
import {isEmpty} from 'lodash';

class PrivateNavSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,

      searchType: 'certified-entity',
      label: 'Company Name',
    };

    this.toggle = this.toggle.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onSelectChange(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      searchType: event.target.value,
      label: event.target.innerText,
      active: 'certified-entity',
    });
  }

  render() {
    const {location} = this.props;
    const {label, dropdownOpen, searchType} = this.state;
    const {eventSearchInput, eventSearchClick} = DataLayerPush;

    const {pathname} = location;

    return (
      <CaptchaModal initialCheck={false}>
        {({verifyCaptcha}) => {
          return (
            <div className={styles['search__forms']}>
              <InputGroup className="onboard-search-verify">
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle
                    className={styles['form-control__select']}
                    caret
                  >
                    {label}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      value="certified-entity"
                      onClick={(event) =>
                        this.onSelectChange(event, 'certified-entity')
                      }
                    >
                      Company Name
                    </DropdownItem>
                    <DropdownItem
                      value="certification"
                      onClick={(event) =>
                        this.onSelectChange(event, 'certification')
                      }
                    >
                      Certification ID
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>

                <div onClick={verifyCaptcha}>
                  {searchType === 'certified-entity' ? (
                    <SearchSelectVerification
                      key={pathname}
                      type="company"
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'ce',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_id, company_name} = selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_id,
                          });
                        }
                      }}
                    />
                  ) : (
                    <SearchSelectVerification
                      key={pathname}
                      type="certification"
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'cert',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_certifications_id, company_name} =
                          selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_certifications_id,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </InputGroup>
            </div>
          );
        }}
      </CaptchaModal>
    );
  }
}

export default withRouter(withAuth(PrivateNavSearch));
