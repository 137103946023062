import {Alert} from 'reactstrap';
import {useLocation} from 'react-router';
import React, {useEffect, useState} from 'react';
import {components} from 'react-select';

import {IconFA} from 'components/Icons';
import {SearchSelectCE, SearchSelectCert} from 'components/Forms/Searches';

import cleanDeep from 'clean-deep';
import classNames from 'classnames';
import {alertByError} from 'utils/alerts';
import {isEmpty, map, uniqBy, first, slice} from 'lodash';

import {getAccountHistory} from 'api/accounts';
import {useSubscription} from 'contexts/SubscriptionContext';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {useAuth} from 'contexts/AuthContext';
import {COMPANY_TYPE} from 'configs/types';

function SearchCertVerification(props) {
  const {type, ...rest} = props;
  const isCert = type === 'certification';

  const [data, setData] = useState([]);
  const [limitData, setLimitData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const {companyType} = useAuth();
  const isCompany = companyType === COMPANY_TYPE.company;

  const location = useLocation();
  const {isSubscribed} = useSubscription();

  async function getData() {
    setIsLoading(true);
    try {
      const {data} = await getAccountHistory({filter: {type: type}});
      setData(data);
    } catch (error) {
      alertByError(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
    setLimitData([]);
  }, [location, type]);

  const recentData = uniqBy(
    map(data, (item) =>
      cleanDeep({
        ces: map(item.mdb_ces, (i) => i.company_name),
        company_certifications_id: item.company_certifications_id,
        cert_number: item.certification_number,
        company_id: item.company_id,
        company_name: item.company_name,
      })
    ),
    isCert ? 'company_certifications_id' : 'company_id'
  );

  const customOptionsMessage = () => <span>No recent searches</span>;

  const MenuList = (props) => {
    const {selectProps} = props;
    const {inputValue} = selectProps;

    const {try: limitTry, max: limitMax} = limitData || {};
    const limitReached = limitTry === limitMax;

    let text = limitReached
      ? 'You have reached your verification limit.'
      : `You have used ${limitTry}/${limitMax} of your verifications`;

    return (
      <div className="p-3 d-flex flex-column">
        {isLoading || isEmpty(limitData) ? (
          <span className="text-muted align-self-center">Loading...</span>
        ) : (
          <div>
            {!isSubscribed && (
              <>
                <Alert fade={false} color={limitReached ? 'danger' : 'primary'}>
                  <IconFA
                    name={limitReached ? 'exclamation-circle' : 'info-circle'}
                    className="mr-2"
                  />
                  {text}
                </Alert>

                <hr />
              </>
            )}

            {!isEmpty(recentData) && !inputValue && (
              <h6 className="font-weight-bold text-dark">Recent Searches</h6>
            )}
            <components.MenuList {...props} />
          </div>
        )}
      </div>
    );
  };

  const CustomOption = (props) => {
    const {data, ...rest} = props;
    const {cert_number, ces = []} = data || {};

    if (!ces) {
      return <components.Option {...rest}>{cert_number}</components.Option>;
    }

    const firstCE = first(ces) || '';
    const restCEs = slice(ces, 1);

    return (
      <components.Option {...rest}>
        <p
          className={classNames(
            'text-dark font-weight-bold p-0',
            ces && 'mb-1'
          )}
          style={{fontSize: '12px'}}
        >
          {cert_number}
        </p>

        {ces && (
          <div>
            {firstCE && (
              <p
                class="text-secondary p-0 m-0"
                style={{fontSize: '12px'}}
                title={firstCE}
              >
                <span style={{overflowWrap: 'break-word'}}>{firstCE}</span>
                {restCEs.length > 0 && (
                  <span
                    style={{color: 'var(--gray)', filter: 'opacity(0.5)'}}
                    className="ml-1"
                  >
                    (+{restCEs.length} more)
                  </span>
                )}
              </p>
            )}
          </div>
        )}
      </components.Option>
    );
  };

  const _components = {
    IndicatorSeparator: null,
    DropdownIndicator: () => (
      <IconFA name="search" className="mr-2 text-secondary" />
    ),
    MenuList: (props) => <MenuList {...props} />,
    Option: (props) =>
      isCert ? <CustomOption {...props} /> : <components.Option {...props} />,
  };

  const selectProps = ENABLE_SUBSCRIPTION &&
    isCompany && {
      cacheOptions: true,
      callback: setLimitData,
      components: _components,
      defaultOptions: recentData,
      customOptionsMessage: customOptionsMessage,
    };

  return (
    <>
      {isCert ? (
        <SearchSelectCert {...selectProps} {...rest} />
      ) : (
        <SearchSelectCE {...selectProps} {...rest} />
      )}
    </>
  );
}

export default SearchCertVerification;
