import React from 'react';
import {ScaleLoader} from 'react-spinners';

function LoadingSpinner(props) {
  const {isLoading, className} = props;

  return (
    <span id="data-loading" className={className} data-loading={true}>
      <ScaleLoader color="#107ad9" height={30} isLoading={isLoading} />
    </span>
  );
}

export default LoadingSpinner;
